<template>
  <div id="add-products">
    <v-container>
      <h1 class="mt-4">Add Product Details</h1>
      <ProductsForm
        :initial-product="product"
        @save="createNewProduct"
        :is-view="false"
      />
      <Alert
        :showAlert="saveClicked.isClicked"
        :type="saveClicked.type"
        allowDismiss
        absolute
        :text="saveClicked.message"
        :inPage="false"
        :backgroundColour="true"
      />
    </v-container>
  </div>
</template>
<script>
import { Alert } from '@nswdoe/doe-ui-core'
import ProductsForm from '@/views/Products/form'
import { DEFAULT_PRODUCT } from '@/constants/initial'
import { GENERIC_ERROR } from '@/constants/common'

export default {
  name: 'AddProducts',
  title: 'Add Products - SAIS Operation UI Portal',
  components: {
    ProductsForm,
    Alert,
  },
  data() {
    return {
      product: { ...DEFAULT_PRODUCT },
      saveClicked: {
        isClicked: false,
        isSuccess: false,
        message: '',
        type: 'success',
      },
    }
  },
  methods: {
    async createNewProduct(item) {
      try {
        const response = await this.$store.dispatch('moduleProducts/postProduct', item)
        this.saveClicked = {
          isClicked: true,
          type: response.isError ? 'error' : 'success',
          isSuccess: !response.isError,
          message: response.isError
            ? response.errorMessage
            : 'The changes have been saved successfully',
        }

        if (!response.isError) {
          this.product = { ...DEFAULT_PRODUCT }
          this.$router.push('/serviceregistry/products?created=true')
        }
      } catch (error) {
        this.saveClicked = {
          isClicked: true,
          type: 'error',
          isSuccess: false,
          message: GENERIC_ERROR,
        }
      }
    },
  },
}
</script>
